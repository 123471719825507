
'use client';

import { Box, Heading, Stack} from '@chakra-ui/react';
import Image from 'next/image';

import notfound from '@assets/notfound.png';
import { CustomText } from '@components/Custom-text';

export function NotFoundView() {
    return (
        <Stack
            py={{ base: 4, md: 20 }}
            mr={{ base: 0, md: -30, lg: -20 }}
            direction={{ base: 'column-reverse', md: 'row' }}
            justifyContent={{ base: 'center', md: 'space-evenly' }}
            width="initial"
        >
            <Stack
                spacing={2}
                py={{ base: 4, md: 40 }}
                alignItems={{ base: 'center', md: 'flex-start' }}
            >
                <Heading display="flex" alignItems="center">
                    <Box as="span" color="primary.dark">
                        404.&nbsp;
                    </Box>
                    <CustomText color="faqsSubText" size="lg">
                        Error occurred.
                    </CustomText>
                </Heading>
                <Box textAlign="center" mt={4}>
                    <CustomText color="mainText" size="3xl">
                        Woops. Looks like
                    </CustomText>
                    <CustomText color="mainText" size="3xl">
                        this page doesn’t
                    </CustomText>
                    <CustomText color="mainText" size="3xl">
                        exist.
                    </CustomText>
                </Box>

                <Box mt={4}>
                    <CustomText color="faqsSubText" size="lg">
                        back to
                        <Box
                            as="a"
                            href="/"
                            color="primary.dark"
                            ml={2}
                        >
                            Home.&nbsp;
                        </Box>
                    </CustomText>
                </Box>
            </Stack>

            <Stack mr={{ base: 0, sm: 0, md: 0, lg: 20 }}>
                <Image priority src={notfound} alt="A Person Crying" />
            </Stack>
        </Stack>
    );
}
